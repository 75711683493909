var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.$t('Documents'),"icon":"mdi-file-document-multiple-outline","no-content-condition":_vm.items.length === 0,"no-content-text":"documentflow.document.no_content","fill-height":"","filters":_vm.filtersSelect},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.itemsFiltered,"headers":_vm.headers,"search":_vm.$store.state.settings.filtersData.search,"row-action":function ($event) { return _vm.openDocumentDialog($event); },"context-actions-select":_vm.documentActionsSelect},on:{"contextAction":function ($event , item) { return _vm.documentAction($event,item); }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.MONTH_DAY_TIME(item.date , false)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticStyle:{"min-width":"300px"},style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.name))])]}},{key:"item.structure_entity_name",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"shortenText"},[_vm._v(_vm._s(item.structure_entity_name))])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.code))])]}},{key:"item.is_signed",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(!item.is_declined)?_c('v-icon',{attrs:{"color":item.is_signed ? _vm.wsACCENT : null}},[_vm._v("mdi-check-decagram")]):_c('v-icon',{attrs:{"color":_vm.wsATTENTION}},[_vm._v("mdi-alert")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var hover = ref.hover;
return [_c('div',{staticStyle:{"width":"40px"}},[_c('ft-select',{attrs:{"items":_vm.documentActionsSelect},on:{"input":function($event){return _vm.documentAction($event , item)}}},[(hover)?_c('v-btn',{attrs:{"icon":"","color":_vm.wsACCENT}},[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1):_vm._e()],1)],1)]}}])})]},proxy:true},{key:"dialog",fn:function(){return [(_vm.displayDocumentDialog)?_c('document-dialog',{attrs:{"document":_vm.selectedDocument},model:{value:(_vm.displayDocumentDialog),callback:function ($$v) {_vm.displayDocumentDialog=$$v},expression:"displayDocumentDialog"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}})}
var staticRenderFns = []

export { render, staticRenderFns }