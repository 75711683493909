<template>
  <ws-dialog
      v-model="display"
      :title="$t('ViewDocument')"
      fullscreen
      not-centered
      no-navigation
      no-padding
      blank
  >


    <div class="d-flex flex-row " style="height: 100%">
      <v-sheet class="flex-grow-1 fill-height"  height="100%">
          <ws-pdf
              class="mx-auto mb-n2"
              fill-height max-width="800" :src="pdfURL"
          />
      </v-sheet>

      <v-sheet :color="wsLIGHTCARD" class="d-flex flex-column"   width="360">

        <div class="d-flex align-center justify-space-between py-3 px-3 pl-5">
          <h3>{{ $t('ViewDocument') }}</h3>
          <v-btn icon @click="display = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="px-5 mt-6 flex-grow-1 overflow-y-auto" >


          <ws-button
              v-if="allowEdit"
              @click="openEdit"
              :color="wsACCENT"
              label="Редагувати"
              class="mb-5" outlined
              block
          />

          <div
              v-if="document.is_declined"
              :style="`border : 1px solid ${wsDARKLIGHT}`"
              class=" align-center pa-3 wsRoundedLight mb-5" >

            <div class="d-flex">
              <v-icon class="mr-3" :color="wsATTENTION">mdi-alert</v-icon>
              <h4 class="">На доопрацюванні</h4>
            </div>

            <div v-if="document.decline_user">
              <h5 class="mt-5 font-weight-regular " :style="`color: ${wsACCENT}`">Відмовлено</h5>
              <h5>{{ document.decline_user.name }}</h5>
            </div>

            <h5 class="mt-5 font-weight-regular " :style="`color: ${wsACCENT}`">Причина</h5>
            <h5>{{ document.decline_resolution }}</h5>

            <div v-if="document.decline_file_name" class="mt-1">
              <h5 class="mt-5 font-weight-regular " :style="`color: ${wsACCENT}`">Додаток</h5>
              <div class="d-flex align-center">
                <v-icon :color="wsACCENT" class="mr-1">mdi-file</v-icon>
                <h5 @click="downloadFile(document.decline_file_data)" class=" linkHover pointer">
                  {{ SHORTEN_FILE_NAME( document.decline_file_name) }}
                </h5>
              </div>
            </div>

            <div class="mt-5 ">
              <ws-button @click="openEdit" label="Редагувати" block />
            </div>


          </div>


          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular">{{ $t('Document') }}</h5>
          <h5>{{ document.name }}</h5>

          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('Download') }}</h5>
          <div class="d-flex align-center">
            <v-icon :color="wsACCENT" class="mr-1">mdi-file</v-icon>
            <h5 @click="downloadFile(document.file_data)" class=" linkHover pointer">
              {{ SHORTEN_FILE_NAME( document.file_name) }}
            </h5>
          </div>

          <div v-if="document.extra_file">
            <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('DownloadExtraFile') }}</h5>
            <div class="d-flex align-center">
              <v-icon :color="wsACCENT" class="mr-1">mdi-file</v-icon>
              <h5 @click="downloadFile(document.extra_file_data)" class=" linkHover pointer">
                {{ SHORTEN_FILE_NAME( document.extra_file_name) }}
              </h5>
            </div>
          </div>

          <div v-if="document.original_file_name">
            <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('Original') }}</h5>
            <div class="d-flex align-center">
              <v-icon :color="wsACCENT" class="mr-1">mdi-file</v-icon>
              <h5 @click="downloadFile(document.original_file_data)" class=" linkHover pointer">
                {{ SHORTEN_FILE_NAME( document.original_file_name) }}
              </h5>
            </div>
          </div>




          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('Type') }}</h5>
          <h5>{{ document.type_name }}</h5>

          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('StructureEntity') }}</h5>
          <h5>{{ document.structure_entity_name || $t('None') }}</h5>

          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('Date') }}</h5>
          <h5>{{ MONTH_DAY_YEAR(document.date)   }}</h5>

          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('Code') }}:</h5>
          <h5>{{ document.code   }}</h5>

          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('documentflow.document.public_key') }}:</h5>
          <h5>{{ document.public_key || $t('None') }}</h5>

          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-5">{{ $t('documentflow.document.created_by') }}:</h5>
          <h5>{{ document.owner_name || $t('None') }}</h5>


          <!-- Signators-->
          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular mt-8">{{ $t('Signators') }}</h5>

          <div v-for="(item , i) in signators" :key="'signator_' + i" class="mt-3">
            <h5>{{ item.name }} </h5>

            <h5 class="font-weight-regular">
              {{ item.position_name }}
            </h5>
            <h5 class="my-3" v-if="item.resolution">{{ item.resolution }} </h5>
            <h5 v-if="item.date_signed" class="font-weight-regular">
              {{ $t('Signed') }} : {{ MONTH_DAY_YEAR(item.date_signed) }}
            </h5>
            <h5 v-else-if="document.is_declined && document.decline_user && document.decline_user.id === item.user_id" class="font-weight-regular"
                :style="`color : ${wsATTENTION}`">
              {{ $t('Declined') }}
            </h5>
            <h5 v-else class="font-weight-regular" :style="`color : ${wsDARKLIGHT}`">
              {{ $t('NotSigned') }}
            </h5>
          </div>

          <!-- Aprovals -->
          <h5 v-if="aprovals.length > 0" :style="`color : ${wsACCENT}`" class="font-weight-regular mt-3">{{ $t('AprovedBy') }}</h5>
          <div v-for="(item , i) in aprovals" :key="'signator_' + i" class="mt-5">
            <h5>{{ item.name }} </h5>
            <h5 class="font-weight-regular">
              {{ item.position_name }}
            </h5>
            <h5 class="my-3" v-if="item.resolution">{{ item.resolution }} </h5>
            <h5 v-if="item.date_signed" class="font-weight-regular">
              {{ $t('Signed') }} : {{ MONTH_DAY_YEAR(item.date_signed) }}
            </h5>
            <h5 v-else-if="document.is_declined && document.decline_user && document.decline_user.id === item.user_id"
                :style="`color : ${wsATTENTION}`">
              {{ $t('Declined') }}
            </h5>
            <h5 v-else class="font-weight-regular" :style="`color : ${wsDARKLIGHT}`">
              {{ $t('NotSigned') }}
            </h5>

          </div>

        </div>

        <div v-if="!forSign || documentIsSigned" class="d-flex justify-end px-5 py-3">
          <ws-button @click="display = false" label="Close" outlined :color="wsACCENT" class="mr-3"/>
        </div>
        <div v-else class="d-flex justify-space-between px-5 py-3">
          <ws-button @click="openDeclineDocument" label="documentflow.signature.decline.title" outlined :color="wsACCENT" class="mr-3"/>

          <div class="d-flex">
            <ws-button @click="openSignDialog" label="Sign"/>
          </div>
        </div>


      </v-sheet>
    </div>


    <!-- Sign Dialog-->
    <ws-dialog
        v-model="displaySignDialog"
        :title="$t('documentflow.document.sign')"
        @save="handleSignButton"
        :save-text="signFileButtonText"
        :disable-save="disableSaveCondition"
    >

      <div v-if="!document.is_signed">
        <h4 class="font-weight-regular mb-5">{{ $t('documentflow.signature.confirmation_text') }}</h4>
        <ws-text-field

            v-model="signEntityData.resolution"
            :label="$t('documentflow.signature.resolution.title')"
            :placeholder="$t('documentflow.signature.resolution.placeholder')"
            area/>
      </div>

      <div v-else-if="document.has_extra_protection && !document.is_extra_signed">
        <h4 class="font-weight-regular mb-5">Документ потребує додаткового захисту через Дія підпис</h4>


        <h5 class="font-weight-regular ">1. Завнтажте документ собі на компьютер</h5>

        <div class="my-3">
          <v-hover #default="{hover}" >

            <v-sheet
                @click="downloadFile(document.extra_file_data || document.file_data)"
                class="d-flex align-center wsRoundedLight pr-10 py-2 px-3 pointer" :color="!hover ? wsLIGHTCARD : wsBACKGROUND" >
              <v-icon class="mr-2" :color="wsACCENT">mdi-file</v-icon>
              <h5 :class="[{'text-decoration-underline' : hover}]">{{ SHORTEN_FILE_NAME( document.extra_file_name || document.file_name) }}</h5>

            </v-sheet>

          </v-hover>
        </div>



        <h5 class="font-weight-regular ">2. Перейдіть на сторінку <a class="noUnderline linkHover" target="_blank" href="https://diia.gov.ua/services/pidpisannya-dokumentiv" :style="`color : ${wsATTENTION}`">Дія підпис</a></h5>
        <h5 class="font-weight-regular ">3. Накладіть цифровий підпис на документ</h5>
        <h5 class="font-weight-regular mb-5">4. Завантажте отриманий документ в форму нижче</h5>

        <ws-file-uploader
            v-if="!extraSignedFile.uuid"
            @success="extraSignedFile = $event"
        />
        <v-sheet
            v-else
            :color="wsLIGHTCARD"
            class="d-flex justify-space-between align-center wsRoundedLight px-4 py-2"
        >
          <div class="d-flex align-center">
            <v-icon :color="wsACCENT">mdi-file</v-icon>
            <h5 class="mx-3">{{SHORTEN_FILE_NAME(extraSignedFile.name)}}</h5>
          </div>
          <v-btn @click="extraSignedFile = {}" :color="wsACCENT" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-sheet>
      </div>

    </ws-dialog>

    <!-- Decline Dialog-->
    <ws-dialog
        v-model="displayDecline"
        :title="$t('documentflow.signature.decline.title_dialog')"
        @save="declineDocument"
        :save-text="$t('documentflow.signature.decline.title')"
    >

      <ws-text-field
          v-model="declineEntityData.decline_resolution"
          :label="$t('documentflow.signature.decline.reason')"
          :placeholder="$t('documentflow.signature.decline.reason_placeholder')"
          area
      />

      <h5 class="mt-5 mb-3 font-weight-regular" :style="`color : ${wsACCENT}`">Прікріпити файл</h5>
      <ws-file-uploader
          v-if="!declineFile.uuid"
          @success="declineFile = $event"
      />
      <div v-else class="d-flex align-center justify-space-between">
        <h5 class="d-flex align-center mr-2"><v-icon :color="wsACCENT">mdi-file</v-icon>{{declineFile.name}}</h5>
        <v-btn icon :color="wsACCENT" @click="declineFile={}"><v-icon>mdi-close</v-icon></v-btn>
      </div>

    </ws-dialog>


  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "documentDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    allowEdit : {
      type : Boolean,
      default : false
    },
    document : {
      type : Object,
      default() { return {} }
    },
    forSign : {
      type : Boolean,
      default : false
    },
    updateTrigger : {
      type : Number,
    }
  },
  data() {
    return {
      display : false,
      signators : [],
      aprovals : [],
      pdfURL : null,
      displayDecline : false,
      declineEntityData : {},
      declineFile : {},
      displayRework : false,
      signEntityData : {},
      displaySignDialog : false,
      extraSignedFile : {}
    }
  },
  computed : {
    disableSaveCondition() {
      if ( this.LOADING) {
        return true
      }

      if ( this.document.has_extra_protection && this.document.is_signed && !this.extraSignedFile.uuid) {
        return true
      }

      return false
    },
    signFileButtonText() {
      if ( this.document.has_extra_protection ) {
        return this.$t(!this.document.is_signed ? 'Next' : 'Upload')
      }
      return this.$t('documentflow.document.sign')
    },
    documentIsSigned() {
      if ( this.document.has_extra_protection) {
        return this.document.is_signed && this.document.is_extra_signed
      }
      return this.document.is_signed
    }
  },
  watch : {
    updateTrigger() {
      this.initData()
      this.initPreview()
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display )
      }
    }
  },
  methods : {
    ...mapActions('documentflow', [
      'GET_DOCUMENT_DETAILS',
      'DECLINE_DOCUMENT',
    ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    handleSignButton() {
      if ( this.extraSignedFile.uuid) {
        let data = {
          uuid : this.document.uuid,
          extra_file_id : this.extraSignedFile.uuid
        }
        this.$emit('upload-extra' ,  data )
        return
      }
      this.$emit('sign' ,  this.signEntityData )
    },
    openSignDialog() {
      this.extraSignedFile = {}
      this.signEntityData = {}
      this.displaySignDialog = true
    },
    openEdit() {
      let data = this.COPY(this.document)
      data.signators = this.signators
      data.aprovals = this.aprovals
      data.file = null
      this.$emit('edit' , data)
    },
    async declineDocument() {
      if ( !this.declineEntityData.decline_resolution ) {
        this.notify(this.$t('documentflow.signature.decline.reason_placeholder') , 'warning')
        return
      }
      if ( this.declineFile.uuid ) {
        this.declineEntityData.decline_file_id = this.declineFile.uuid
      }
      let result = await this.DECLINE_DOCUMENT(this.declineEntityData)
      if (!result) {
        return this.ERROR()
      }
      this.$emit('decline')
      this.displayDecline = false
    },
    openDeclineDocument() {
      this.declineFile = {}
      this.displayDecline = true
      this.declineEntityData = {
        uuid : this.document.uuid
      }
    },
    async initData() {
      let result = await this.GET_DOCUMENT_DETAILS(this.document.uuid)
      if (!result) {
        this.ERROR()
      }
      this.signators = result.signators
      this.aprovals = result.aprovals
    },
    async downloadFile(file = null) {

      if ( !file ) {
        file = this.COPY(this.document.file_data)
      }

      let result = await this.GET_PRIVATE_FILE(file.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();

    },
    async initPreview() {

      let result = await this.GET_PRIVATE_FILE(this.document.file_data.uuid)
      if (!result) { return this.notify('File Not found') }
      this.pdfURL = `${result.url}&noforce=true`

    },
  },
  mounted() {
    this.extraSignedFile = false
    if ( this.value ) {
      this.display = this.value
    }
    this.initData()
    this.initPreview()
  }
}
</script>

<style scoped>

</style>